import RefreshButton from "./RefreshButton";
import CallsFilter from "./CallsFilter";

export const PaginationAndFilter = ({
                                        clearDates,
                                        fromDate,
                                        toDate,
                                        setFromDate,
                                        setToDate,
                                        displayRefresh = false,
                                        hasNext,
                                        hasPrevious,
                                        goPrevious,
                                        goNext,
                                        isLoading,
                                        refreshFn,
                                        isError,
                                        lat,
                                        lng,
                                        setLat,
                                        setLng
                                    }) => {
    return <div className="flex items-center justify-between bg-gray-100 px-4 py-3 sm:px-6">
        <div className="flex flex-1 justify-between">
            <button
                onClick={goPrevious}
                className={"whitespace-nowrap relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium " + (hasPrevious ? " text-gray-700 hover:bg-gray-50" : " text-gray-400")}
                disabled={!hasPrevious}
            >
                {"< Previous"}
            </button>
            {displayRefresh && <div className="inline-flex">
                <CallsFilter
                    clearDates={clearDates}
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    lat={lat}
                    lng={lng}
                    setLat={setLat}
                    setLng={setLng}
                />
                <RefreshButton displaySpinner={isLoading}
                               refreshFn={refreshFn}
                               getErrorMessage={() => isError ? "There was some problem while loading call data, please try refreshing the browser" : ""}/>
            </div>
            }
            <button
                onClick={goNext}
                className={"whitespace-nowrap relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium " + (hasNext ? " text-gray-700 hover:bg-gray-50" : " text-gray-400")}
                disabled={!hasNext}
            >{"Next >"}
            </button>
        </div>
    </div>;
}