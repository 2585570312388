const config = {
    Auth: {
        identityPoolId: 'ca-central-1:5ec25d24-1e42-4dcb-a10b-3c0a56540d3b',
        region: 'ca-central-1',
        userPoolId: 'ca-central-1_NF7MNVhGx',
        userPoolWebClientId: '5aidat8u7pgr9tpgbc72504sqg',
        mandatorySignIn: true,
        authenticationFlowType: 'USER_SRP_AUTH',
    },
    endpoints: [
        {
            name: 'dev-psap',
            endpoint: 'https://pdvq9p0ew3.execute-api.ca-central-1.amazonaws.com/dev-psap-stage/psap-ui',
            region: 'ca-central-1'
        }
    ]
};

export default config;