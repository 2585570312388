import {withAuthenticator} from '@aws-amplify/ui-react';
import CallsContainer from './comp/CallsContainer';

function App({signOut, user}) {
    return user ? <div className="flex flex-col h-full w-full bg-gray-100">
        <header className="mt-2 z-50 flex flex-row mx-auto w-full max-w-6xl sm:px-2 py-4 justify-between">
            <img src="logo512.png" className="w-12 mr-3" alt="ELi Logo"/>
            <span className="text-slate-600 mt-1 mx-auto text-2xl font-medium mr-3">Demo PSAP</span>
            <button
                className="ml-auto mr-2 h-8 text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded inline-flex items-center"
                onClick={signOut}>
                {LogOutIcon}
                Log Out
            </button>
        </header>
        <main>
            <CallsContainer/>
        </main>
    </div> : null;
}

export default withAuthenticator(App, {hideSignUp: true});

const LogOutIcon = <svg className="h-5 w-5 mr-1 text-blue-600" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2"
                        stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
    <path stroke="none" d="M0 0h24v24H0z"/>
    <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"/>
    <path d="M7 12h14l-3 -3m0 6l3 -3"/>
</svg>;
